<template>
  <div class="header">
    <div class="head-left">
      <div class="logo" @click="goRouetr">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="desc">
        <div class="name">2024文旅博览会</div>
        <div class="address-time">
          <div class="time">
            <img src="../assets/img/clock-icon.png" alt="" /><span
              >2024年10月11日-13日</span
            >
          </div>
          <div class="address">
            <img src="../assets/img/address-icon.png" alt="" /><span
              >合肥·滨湖国际会展中心</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="head-right">
      <img src="../assets/img/24-hours.png" alt="" /><span>0551-5215-1381</span>
    </div>
    <div class="m-head">
      <div class="logo" @click="goRouetr">
        <img src="../assets/img/w-logo.png" alt="" />
        <div class="name">2024文旅博览会</div>
      </div>
      <div class="nav">
        <div class="btn-nav" @click="drawer = true">
          <div class="icon-bar"></div>
          <div class="icon-bar"></div>
          <div class="icon-bar"></div>
        </div>
      </div>
    </div>
    <el-drawer
      :withHeader="false"
      :visible.sync="drawer"
      size="60%"
      :before-close="handleClose"
    >
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        router
        @select="select"
      >
        <template>
          <div v-for="(item, index) in navList" :key="index">
            <el-menu-item :index="item.router" v-if="!item.children">
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-submenu :index="item.router" v-else>
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item-group v-for="(e, i) in item.children" :key="i">
                <el-menu-item :index="e.router">{{ e.name }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </div>
        </template>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { mice } from "@/api";
export default {
  props: {
    mcieObj: {},
  },
  data() {
    return {
      drawer: false,
      navList: [
        {
          name: "首页",
          router: "/",
        },
        {
          name: "关于展会",
          router: "/about/1ZapaixzjzhELFR8N9McL8",
          children: [
            {
              name: "展会介绍",
              router: "/about/1ZapaixzjzhELFR8N9McL8",
            },
            {
              name: "展馆列表",
              router: "/pavilion",
            },
            {
              name: "展区分布",
              router: "/distribution",
            },
            // {
            //   name: "展览模块",
            //   router: "/display-module",
            // },
            {
              name: "展商名录",
              router: "/Exhibitor-list",
            },
          ],
        },
        {
          name: "同期活动",
          router: "/actives",
        },
        {
          name: "会议论坛",
          router: "/meeting",
        },
        {
          name: "新闻中心",
          router: "/information",
          children: [
            {
              name: "新闻资讯",
              router: "/information",
            },
            {
              name: "展会咨询",
              router: "/news",
            },
          ],
        },
        {
          name: "参展指南",
          router: "/catalogue",
          children: [
            {
              name: "展商手册",
              router: "/catalogue",
            },
            {
              name: "出行指南",
              router: "/travel/25Aj20d2mtJFnup5RTOMzy",
            },
            {
              name: "住宿指南",
              router: "/accommodation/2YXx3PjEdWe4m2dj7DUmEW",
            },
          ],
        },
        {
          name: "联系我们",
          router: "/team/7EadgzINeTpvACvuAzteri",
          children: [
            {
              name: "联系方式",
              router: "/cantact-way/7EadgzINeTpvACvuAzteri",
            },
          ],
        },
        {
          name: "下载中心",
          router: "/data-center",
          children: [
            {
              name: "资料中心",
              router: "/data-center",
            },
          ],
        },
        {
          name: "合作媒体",
          router: "/media",
        },
      ],
    };
  },
  created() {},
  methods: {
    handleClose(e) {
      this.drawer = false;
    },
    goRouetr(path) {
      this.$router.push({
        path: "/",
      });
    },
    select(e) {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #fff;
  padding: 14px 287px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .head-left {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 40px;

      img {
        width: 66px;
        height: 86px;
      }
    }

    .desc {
      .name {
        font-size: 32px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 38px;
        margin-bottom: 8px;
      }

      .address-time {
        display: flex;

        .address,
        .time {
          display: flex;
          align-items: center;
          margin-right: 34px;
        }
      }
    }
  }

  .head-right {
    display: flex;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    span {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #4f4f4f;
      line-height: 28px;
    }
  }

  .m-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    display: none;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 48px;
        height: 58px;
        margin-right: 12px;
      }

      .name {
        font-weight: 800;
        font-size: 16px;
      }
    }

    .nav {
      .btn-nav {
        background: transparent;
        border: none;
        padding: 20px;
        transition: all 0.5s ease;
        cursor: pointer;
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        .icon-bar {
          display: block;
          margin: 6px 0;
          width: 30px;
          height: 2px;
          background-color: #fff;

          &::before {
            content: "";
            width: 4px;
            height: 4px;
            background: #fff;
            position: absolute;
            left: 10px;
            border-radius: 40%;
          }
        }
      }
    }
  }
}

@media screen and(max-width:828px) {
  .header {
    padding: 12px;
    background: #1d71d1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-left {
      display: none;
    }

    .head-right {
      display: none;
    }

    .m-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;

      .logo {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 48px;
          margin-right: 12px;
        }

        .name {
          font-weight: 800;
          font-size: 16px;
        }
      }

      .nav {
        .btn-nav {
          background: transparent;
          border: none;
          padding: 15px;
          transition: all 0.5s ease;
          cursor: pointer;
          z-index: 1;
          display: flex;
          flex-direction: column;
          position: relative;

          .icon-bar {
            display: block;
            margin: 4px 0;
            width: 22px;
            height: 2px;
            background-color: #fff;

            &::before {
              content: "";
              width: 3px;
              height: 3px;
              background: #fff;
              position: absolute;
              left: 10px;
              border-radius: 40%;
            }
          }
        }
      }
    }
  }

  ::v-deep .el-drawer__body {
    padding: 20px 0;
  }

  ::v-deep.el-menu-item {
    font-size: 16px;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  ::v-deep.el-submenu {
    font-size: 16px;
    line-height: 21px;

    // height: 30px;
    .el-submenu__title {
      font-size: 16px;
      line-height: 21px;
      height: 40px;
      display: flex;
      align-items: center;
    }

    .el-menu-item {
      font-size: 14px;
      line-height: 21px;
      height: 30px;
      color: #666;
    }

    .el-submenu__icon-arrow,
    .el-icon-arrow-down {
      font-size: 14px;
    }
  }
}
</style>
