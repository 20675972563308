import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/css/index.scss'
Vue.use(ElementUI);
import mixin from '@/assets/js/mixin'
Vue.mixin(mixin)
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/swiper.scss'

Vue.use(VueAwesomeSwiper)
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

//或者自定义配置插件
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/img/1/2.png'),
  loading: require('./assets/img/1/1.png'),
  attempt: 1
})
import moment from 'moment';
Vue.prototype.$moment = moment;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
