<template>
  <div v-if="mcieObj.miceName" :class="device !== 'mobile' ? 'home' : 'wrap'">
    <div class="position" v-if="device !== 'mobile'">
      <div class="item" @click="goRouetr('/audience-sign-up')">
        <img src="../assets/img/free-icon.png" alt="" />
        <span>参观报名</span>
      </div>
      <div class="item" @click="goRouetr('/sign-up')">
        <img src="../assets/img/zy-icon.png" alt="" />
        <span>采购商报名</span>
      </div>
      <div class="item" @click="goRouetr('/reservation-sign-up')">
        <img src="../assets/img/reservation-icon.png" alt="" />
        <span>展位预定</span>
      </div>
      <div class="item xcx">
        <img src="../assets/img/wx-chat.png" alt="" />
        <span>小程序</span>
        <div class="qrcode">
          <img src="../assets/img/xcx-qrcode.png" alt="" />
        </div>
      </div>

      <div class="item" @click="toTop">
        <img src="../assets/img/top-icon.png" alt="" />
        <span>返回顶部</span>
      </div>
    </div>
    <home-header :mcieObj="mcieObj" />
    <banner
      :checkIndex="checkIndex"
      @changeIndex="changeIndex"
      :isHidden="isHidden"
      :isShowNav="isShowNav"
      :mcieObj="mcieObj"
    />
    <router-view :mcieObj="mcieObj" :dataList="dataList"></router-view>
    <home-footer />
  </div>
</template>

<script>
import { mice } from "@/api";
import footer from "@/components/Footer.vue";
import banner from "@/components/Banner.vue";
import header from "@/components/Header.vue";
import store from "@/store";
import { mapState } from "vuex";

const { body } = document;
const WIDTH = 828;
export default {
  name: "HomeView",
  components: {
    "home-header": header,
    banner: banner,
    "home-footer": footer,
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "getIndex",
  },
  computed: {
    ...mapState(["device"]),
  },
  data() {
    return {
      checkIndex: 0,
      isHidden: false,
      isShowNav: true,
      mcieObj: {},
      dataList: [],
    };
  },
  created() {
    this.getMice();
    this.checkIndex = this.$route.meta.index;
    this.isHidden = this.$route.meta.hidden;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    const isMobile = this.$_isMobile();
    if (isMobile) {
      store.dispatch("toggleDevice", "mobile");
      store.dispatch("closeSideBar", { withoutAnimation: true });
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.$_resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.$_resizeHandler);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getMice() {
      mice({ miceId: this.miceId }).then((res) => {
        if (res.status) {
          this.mcieObj = res.data;
          this.dataList = JSON.parse(res.data.extra_info);
        }
      });
    },
    handleScroll(e) {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top < 20) {
        this.isShowNav = true;
      } else {
        this.isShowNav = false;
      }
    },
    goRouetr(path) {
      this.$router.push({
        path,
      });
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    getIndex() {
      console.log(this.$route);
      this.checkIndex = this.$route.meta.index;
      this.isHidden = this.$route.meta.hidden;
    },
    changeIndex(e) {
      this.checkIndex = e;
      if (this.device === "mobile" && this.sidebar.opened) {
        store.dispatch("closeSideBar", { withoutAnimation: false });
      }
    },
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();
        store.dispatch("toggleDevice", isMobile ? "mobile" : "desktop");

        if (isMobile) {
          store.dispatch("closeSideBar", { withoutAnimation: true });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.position {
  position: fixed;
  z-index: 20;
  right: 0;
  bottom: 10%;
  background-color: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.12);
  border-radius: 8px 0px 0px 8px;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    &:last-child {
      border: 0;
    }
    img {
      width: 42px;
      height: 42px;
      margin-bottom: 20px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: #4f4f4f;
    }
  }
  .xcx {
    position: relative;
    &:hover {
      .qrcode {
        visibility: visible;
        opacity: 1;
      }
    }
    .qrcode {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: -210px;
      top: 0;
      width: 200px;
      height: 200px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      border: 1px solid #ccc;
      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}
</style>
