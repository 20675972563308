<template>
  <div class="footer">
    <div class="top">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12" :md="5" :lg="5" :xl="5">
          <div class="foot-h2">
            <div class="title">关注我们</div>
          </div>
          <div class="qrcodes">
            <div
              class="qrcodes-li"
              v-for="(item, index) in qrcodes"
              :key="index"
            >
              <template>
                <div v-if="checkIndex === index">
                  <div class="name">{{ item.name }}</div>
                  <img class="qrcode-img" :src="item.img" alt="" />
                </div>
              </template>
            </div>
            <div class="icon">
              <div
                class="icon-li"
                v-for="(item, index) in qrcodes"
                :key="index"
                @click="checkIndex = index"
              >
                <img :src="item.icon" alt="" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="hidden-sm-and-down" :md="3" :lg="3" :xl="3">
          <div class="foot-h2">
            <div class="title">我要参展</div>
          </div>
          <div class="title-h3" @click="goRouter('/audience-sign-up')">
            免费门票
          </div>
          <div class="title-h3" @click="goRouter('/reservation-sign-up')">
            展位预定
          </div>
        </el-col>
        <el-col class="hidden-sm-and-down" :md="3" :lg="3" :xl="3">
          <div class="foot-h2">
            <div class="title">关于展会</div>
          </div>
          <div
            class="title-h3"
            @click="goRouter('/about/1ZapaixzjzhELFR8N9McL8')"
          >
            展会介绍
          </div>
          <div class="title-h3" @click="goRouter('/pavilion')">展馆列表</div>
          <div class="title-h3" @click="goRouter('/Exhibitor-list')">
            查找展商
          </div>
          <div class="title-h3" @click="goRouter('/display-module')">
            展览板块
          </div>
          <div class="title-h3" @click="goRouter('/distribution')">
            展区分布
          </div>
        </el-col>
        <el-col :xs="24" :sm="13" :md="13" :lg="13" :xl="13">
          <div class="contact">
            <div class="foot-h2">
              <div class="title">联系我们</div>
            </div>
            <div class="fl-c" style="width: 100%">
              <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value" style="margin-bottom: 10px">
                    <div>参展参会：</div>
                    <div class="contact-person">
                      <div>方先生 13966678689</div>
                      <div>周女士 18788885978</div>
                      <div>梁先生 19965432789</div>
                      <div>杨先生 18655146967</div>
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value" style="margin-bottom: 10px">
                    <div>参观采购：</div>
                    <div class="contact-person">
                      <div>孙先生 15255135451</div>
                    </div>
                  </div>
                  <div class="value" style="margin-bottom: 10px">
                    <div>宣传推广：</div>
                    <div class="contact-person">
                      <div>李先生 18269738795</div>
                    </div>
                  </div>
                  <div class="value" style="margin-bottom: 10px">
                    <div>展台设计：</div>
                    <div class="contact-person">
                      <div>冯先生  13505618989</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="item" style="">
              <div class="label">地址：</div>
              <div class="value">
                安徽省合肥市经开区天都路129号中设创意产业园
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >皖ICP备12010259号-32</a
      >
      Built By 安徽中设国际会展集团 技术支持
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkIndex: 0,
      qrcodes: [
        {
          name: "2024文旅博览会活动首页",
          img: require("@/assets/img/xcx-qrcode.png"),
          icon: require("@/assets/img/xcx-icon.png"),
        },
        {
          name: "官方微信公众号",
          img: require("@/assets/img/gzh-qrcode.png"),
          icon: require("@/assets/img/wx-icon.png"),
        },
        {
          name: "官方抖音号",
          img: require("@/assets/img/dy-qrcode.png"),
          icon: require("@/assets/img/dy-icon.png"),
        },
      ],
    };
  },
  methods: {
    goRouter(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #1d71d1;
  padding: 48px 287px 17px;
  color: #fff;

  .top {
    padding-bottom: 51px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  .qrcodes {
    .name {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
      margin-bottom: 12px;
    }

    .qrcode-img {
      width: 190px;
      height: 190px;
      margin-bottom: 26px;
    }

    .icon {
      display: flex;

      .icon-li {
        margin: 0 18px;
        cursor: pointer;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .foot-h2 {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
    letter-spacing: 2px;
    margin-bottom: 44px;

    &::after {
      content: "";
      display: block;
      width: 33px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      margin-top: 8px;
    }
  }

  .title-h3 {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .contact {
    border-left: 1px dashed #fff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    padding-left: 100px;
    margin-top: 20px;

    .item {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
      .contact-person {
        div {
          margin-bottom: 4px;
          font-size: 12px;
        }
      }
      .label {
        margin-bottom: 4px;
      }
    }
  }

  .copyright {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 26px;
    padding-top: 13px;
    text-align: center;

    a {
      color: #fff;
      margin-right: 2px;
    }
  }
}

@media screen and(max-width:828px) {
  .footer {
    .foot-h2,
    .qrcodes {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .footer {
    background: #1d71d1;
    padding: 24px;
    padding-bottom: 120px;
    color: #fff;

    .top {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    .qrcodes {
      .name {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 21px;
        margin-bottom: 12px;
      }

      .qrcode-img {
        width: 220px;
        height: 220px;
        margin-bottom: 26px;
      }

      .icon {
        display: flex;

        .icon-li {
          margin: 0 18px;
          cursor: pointer;

          img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }

    .foot-h2 {
      font-size: 24px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 28px;
      letter-spacing: 2px;
      margin-bottom: 44px;

      &::after {
        content: "";
        display: block;
        width: 33px;
        height: 3px;
        background: #ffffff;
        opacity: 1;
        margin-top: 8px;
      }
    }

    .title-h3 {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
      margin-bottom: 24px;
      cursor: pointer;
    }

    .contact {
      border: 0;
      padding-left: 0px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      margin-top: 20px;

      .item {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: 21px;

        .label {
          margin-bottom: 4px;
        }
      }
    }

    .copyright {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 26px;
      padding-top: 13px;
      text-align: center;

      a {
        color: #fff;
        margin-right: 2px;
      }
    }
  }
}
</style>
