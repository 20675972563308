import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import Home from '../views/Home.vue'
Vue.use(VueRouter)
// 解决导航重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          index: 0
        }
      },
      {
        path: '/video-center',
        name: 'video-center',
        component: () => import(/* webpackChunkName: "about" */ '../views/News/video-center.vue'),
        meta: {
          index: 0,
          hidden: true
        }
      },
      {
        path: '/sign-up',
        name: 'signUp',
        component: () => import(/* webpackChunkName: "about" */ '../views/signUp.vue'),
        meta: {
          index: 0,
          hidden: true
        },
      },
      {
        path: '/audience-sign-up',
        name: 'audienceSignUp',
        component: () => import(/* webpackChunkName: "about" */ '../views/audience.vue'),
        meta: {
          index: 0,
          hidden: true
        },
      },
      {
        path: '/reservation-sign-up',
        name: 'reservationSignUp',
        component: () => import(/* webpackChunkName: "about" */ '../views/reservation.vue'),
        meta: {
          index: 0,
          hidden: true
        },
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
          index: 1
        },
        children: [
          {
            path: '/about/:id',
            name: 'About',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Index.vue'), meta: {
              index: 1,
              selfIndex: 0
            },
          },
          {
            path: '/pavilion',
            name: 'Pavilion',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Pavilion.vue'), meta: {
              index: 1,
              selfIndex: 1
            },
          },
          {
            path: '/distribution',
            name: 'Distribution',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Distribution.vue'), meta: {
              index: 1,
              selfIndex: 2
            },
          },
          {
            path: '/display-module',
            name: 'Display-module',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Display-module.vue'), meta: {
              index: 1,
              selfIndex: 3
            },
          },
          {
            path: '/exhibitor-list',
            name: 'Exhibitor-list',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Exhibitor-list.vue'), meta: {
              index: 1,
              selfIndex: 4
            },
          }
        ],
      },
      {
        path: '/exhibitor-list/detail',
        name: 'exhibitor-detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/About/Exhibitor-detail.vue'),
        meta: {
          index: 1,
          hidden: true
        },
      },
      {
        path: '/Pavilion/detail',
        name: 'Pavilion-detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/About/Pavilion-detail.vue'),
        meta: {
          index: 1,
          hidden: true
        },
      },
      {
        path: '/actives',
        name: 'Actives',
        component: () => import(/* webpackChunkName: "about" */ '../views/actives.vue'),
        meta: {
          index: 2
        },
      },
      {
        path: '/actives-detail',
        name: 'Actives-detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/Actives-detail.vue'),
        meta: {
          index: 2
        },
      },
      {
        path: '/meeting',
        name: 'Meeting',
        component: () => import(/* webpackChunkName: "about" */ '../views/meeting.vue'),
        meta: {
          index: 3
        },
      },
      {
        path: '/meeting-detail',
        name: 'Meeting-detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/meeting-detail.vue'),
        meta: {
          index: 3
        },
      },
      {
        path: '/information',
        name: 'News',
        component: () => import(/* webpackChunkName: "about" */ '../views/news.vue'),
        meta: {
          index: 4
        },
        children: [
          {
            path: '/information',
            name: 'information',
            component: () => import(/* webpackChunkName: "about" */ '../views/News/information.vue'), meta: {
              index: 4,
              selfIndex: 0
            },
          },
          {
            path: '/information/detail',
            name: 'informationDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/News/Detail.vue'), meta: {
              index: 4,
              selfIndex: 0
            },
          },
          {
            path: '/news',
            name: 'news',
            component: () => import(/* webpackChunkName: "about" */ '../views/News/news.vue'), meta: {
              index: 4,
              selfIndex: 1
            },
          },
          {
            path: '/news/detail',
            name: 'newsDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/News/Detail.vue'), meta: {
              index: 4,
              selfIndex: 1
            },
          },
        ],
      },
      {
        path: '/travel/:id',
        name: 'guide',
        component: () => import(/* webpackChunkName: "about" */ '../views/guide.vue'),
        meta: {
          index: 5
        },
        children: [
          {
            path: '/catalogue',
            name: 'Catalogue',
            component: () => import(/* webpackChunkName: "about" */ '../views/Down/catalogue.vue'), meta: {
              index: 5,
              selfIndex: 0
            },
          },
          {
            path: '/travel/:id',
            name: 'Travel',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Index.vue'), meta: {
              index: 5,
              selfIndex: 1
            },
          },
          {
            path: '/accommodation/:id',
            name: 'Accommodation',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Index.vue'), meta: {
              index: 5,
              selfIndex: 2
            },
          },
          // {
          //   path: '/prevention/:id',
          //   name: 'Prevention',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/About/Index.vue'), meta: {
          //     index: 5,
          //     selfIndex: 2
          //   },
          // },
        ],
      },
      {
        path: '/team/:id',
        name: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue'),
        meta: {
          index: 6
        },
        children: [
          // {
          //   path: '/team/:id',
          //   name: 'Team',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/About/Index.vue'), meta: {
          //     index: 6,
          //     selfIndex: 0
          //   },
          // },
          {
            path: '/cantact-way/:id',
            name: 'CantactWay',
            component: () => import(/* webpackChunkName: "about" */ '../views/About/Index.vue'), meta: {
              index: 6,
              selfIndex: 0
            },
          }
        ],
      },
      {
        path: '/catalogue',
        name: 'Down',
        component: () => import(/* webpackChunkName: "about" */ '../views/down.vue'),
        meta: {
          index: 7
        },
        children: [
          // {
          //   path: '/catalogue',
          //   name: 'Catalogue',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/Down/catalogue.vue'), meta: {
          //     index: 7,
          //     selfIndex: 0
          //   },
          // },
          {
            path: '/data-center',
            name: 'data-center',
            component: () => import(/* webpackChunkName: "about" */ '../views/Down/data-center.vue'), meta: {
              index: 7,
              selfIndex: 0
            },
          },
        ],
      },
      {
        path: '/media',
        name: 'Media',
        component: () => import(/* webpackChunkName: "about" */ '../views/media.vue'),
        meta: {
          index: 8
        },
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 滚动条滚动到哪个的位置
    return { x: 0, y: 0 }
  }
})

export default router
