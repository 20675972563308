import axios from "axios";
import { Message } from 'element-ui';
import qs from 'qs'
let path;
if (process.env.NODE_ENV == "development") {
  path = "/api";
  // path = "";
  // axios.defaults.baseURL = "http://49.232.137.216:10010";
} else if (process.env.NODE_ENV == "production") {
  path = "";
  axios.defaults.baseURL = "http://api.zhongshe.cc/";
}

// 请求超时时间
axios.defaults.timeout = 300000;
// post请求头
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";
// axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (process.env.NODE_ENV == "production") {
      config.baseURL = process.env.VUE_APP_BASEURL;
    } else {
      config.baseURL = "";
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers['Cache-Control'] = 'public';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    // 断网或者请求超时状态
    if (!error.response) {
      if (error.message.includes("timeout")) {
        Message.error({
          message: "请求超时，请检查网络是否连接正常",
          duration: 2000
        });
      } else {
        Message.error({
          message: "请求失败，请检查网络是否已连接",
          duration: 2000
        });
      }
      return;
    }
    // 服务器返回不是 2 开头的情况，会进入这个回调
    // 可以根据后端返回的状态码进行不同的操作
    const responseCode = error.response.status;
    switch (responseCode) {
      // 未登录
      case 401:
        // 跳转到登录页
        // router.replace({
        //   path: "/login",
        //   query: {
        //     redirect: router.currentRoute.fullPath
        //   }
        // });
        break;
      case 500:
        Message.error({
          message: "系统错误，请联系管理员",
          duration: 3000
        });
        break;
      // 其他错误，直接抛出错误提示
      default:
        Message.error({
          message: error.response.data.message || error.response.data,
          duration: 3000
        });
    }
    return Promise.reject(error);
  }
);

export function get(url, params = {}, showLoading = true) {
  url = path + url;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function post(url, params = {}, headers, showLoading = true) {
  url = path + url;
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      headers,
      url,
      data: qs.stringify(params)
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}