import { get, post } from "../assets/js/http";
//发送观众报名验证码
export function getCode(params) {
  return post('/w/attendee/code', params);
}
//验证观众报名验证码/w/attendee/verify 
export function verify(params) {
  return post('/w/attendee/verify', params);
}
//发送展商报名验证码
export function getECode(params) {
  return post('/w/exhibitor/code', params);
}
//验证展商报名验证码
export function verifyE(params) {
  return post('/w/exhibitor/verify', params);
}
//获
//获取文章信息
export function getArticle(params) {
  return get("/w/article", params);
}
//获取文章列表
export function getArticleList(params) {
  return get("/w/article/list", params);
}
//获取分类列表
export function getCategoryList(params) {
  return get("/w/category/list", params);
}
//获取标签列表
export function getTagList(params) {
  return get("/w/tag/list", params);
}
//获取合作伙伴列表
export function friendsList(params) {
  return get("/w/friends/list", params);
}
//展商报名登记
export function exhibitor(params) {
  return post("/w/exhibitor", params);
}
//查看展会信息
export function mice(params) {
  return get("/w/mice", params);
}
//观众报名登记

export function attendee(params) {
  return post("/w/attendee", params);
}
//获取展会、会议、活动列表
export function miceList(params) {
  return get("/w/mice/list", params);
}
//获取新闻列表
export function newsList(params) {
  return get("/w/news/list", params);
}
//获取展会日程列表
export function scheduleList(params) {
  return get("/w/schedule/list", params);
}
//获取展商列表
export function directory(params) {
  return get("/w/directory/list", params);
}
//获取展商详情
export function directoryDetail(params) {
  return get("/w/directory", params);
}
//产品列表
export function products(params) {
  return get("/w/product/list", params);
}
///w/get/exhibit 
export function getexhibit(params) {
  return get("/w/get/exhibit", params);
}
//文件列表
export function getupload(params) {
  return get("/w/upload/list", params);
}
//生成小程序码
export function getcode(params) {
  return get("/w/shared/code", params);
}