<template>
  <div class="banner-nav">
    <div class="banner" v-if="!isHidden && device !== 'mobile'">
      <img
        v-if="
          mcieObj.banner_image && JSON.parse(mcieObj.banner_image).length == 1
        "
        :src="JSON.parse(mcieObj.banner_image)[0]"
        alt=""
      />
    </div>
    <div class="banner" v-if="!isHidden && device == 'mobile'">
      <img
        v-if="
          mcieObj.miniapp_banner_image &&
          JSON.parse(mcieObj.miniapp_banner_image).length == 1
        "
        :src="JSON.parse(mcieObj.miniapp_banner_image)[0]"
        alt=""
      />
    </div>
    <!-- <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <img src="../assets/img/banner.png" alt="" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper> -->
    <div
      v-show="isShowNav && device !== 'mobile'"
      :class="isHidden ? 'nav nav1' : 'nav'"
    >
      <div
        class="nav-li"
        v-for="(nav, index) in navList"
        :key="index"
        @click.prevent="checkRouter(nav, index)"
      >
        <div :class="['nav-name', { active: checkIndex === index }]">
          {{ nav.name }}
        </div>
        <div class="child-box" v-if="nav.children && nav.children.length">
          <div class="nav-child" v-if="nav.children && nav.children.length">
            <div
              class="nav-child-li"
              v-for="(navChild, i) in nav.children"
              :key="i"
              v-if="i < 4"
              @click.stop="checkRouter(navChild, index)"
            >
              {{ navChild.name }}
            </div>
          </div>
          <div class="nav-child" v-if="nav.children && nav.children.length > 4">
            <div
              class="nav-child-li"
              v-for="(navChild, i) in nav.children"
              :key="i"
              v-if="i > 3"
              @click.stop="checkRouter(navChild, index)"
            >
              {{ navChild.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="!isShowNav && device !== 'mobile'"
      :class="isHidden ? 'nav nav1 nav2' : 'nav nav2'"
    >
      <div
        class="nav-li"
        v-for="(nav, index) in navList"
        :key="index"
        @click.prevent="checkRouter(nav, index)"
      >
        <div :class="['nav-name', { active: checkIndex === index }]">
          {{ nav.name }}
        </div>
        <div class="child-box" v-if="nav.children && nav.children.length">
          <div class="nav-child" v-if="nav.children && nav.children.length">
            <div
              class="nav-child-li"
              v-for="(navChild, i) in nav.children"
              :key="i"
              v-if="i < 4"
              @click.stop="checkRouter(navChild, index)"
            >
              {{ navChild.name }}
            </div>
          </div>
          <div class="nav-child" v-if="nav.children && nav.children.length > 4">
            <div
              class="nav-child-li"
              v-for="(navChild, i) in nav.children"
              :key="i"
              v-if="i > 3"
              @click.stop="checkRouter(navChild, index)"
            >
              {{ navChild.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    checkIndex: {},
    isHidden: {
      type: Boolean,
      defalut: false,
    },
    isShowNav: {},
    mcieObj: {
      type: Object,
      defalut: {},
    },
  },
  computed: {
    ...mapState(["device"]),
  },
  data() {
    return {
      navList: [
        {
          name: "首页",
          router: "/",
        },
        {
          name: "关于展会",
          router: "/about/1ZapaixzjzhELFR8N9McL8",
          children: [
            {
              name: "展会介绍",
              router: "/about/1ZapaixzjzhELFR8N9McL8",
            },
            {
              name: "展馆列表",
              router: "/pavilion",
            },
            {
              name: "展区分布",
              router: "/distribution",
            },
            // {
            //   name: "展览模块",
            //   router: "/display-module",
            // },
            {
              name: "展商名录",
              router: "/Exhibitor-list",
            },
          ],
        },
        {
          name: "同期活动",
          router: "/actives",
        },
        {
          name: "会议论坛",
          router: "/meeting",
        },
        {
          name: "新闻中心",
          router: "/information",
          children: [
            {
              name: "新闻资讯",
              router: "/information",
            },
            {
              name: "展会咨询",
              router: "/news",
            },
          ],
        },
        {
          name: "参展指南",
          router: "/catalogue",
          children: [
            {
              name: "展商手册",
              router: "/catalogue",
            },
            {
              name: "出行指南",
              router: "/travel/25Aj20d2mtJFnup5RTOMzy",
            },
            {
              name: "住宿指南",
              router: "/accommodation/2YXx3PjEdWe4m2dj7DUmEW",
            },
          ],
        },
        {
          name: "联系我们",
          router: "/cantact-way/7EadgzINeTpvACvuAzteri",
          children: [
            {
              name: "联系方式",
              router: "/cantact-way/7EadgzINeTpvACvuAzteri",
            },
          ],
        },
        {
          name: "下载中心",
          router: "/data-center",
          children: [
            {
              name: "资料中心",
              router: "/data-center",
            },
          ],
        },
        {
          name: "合作媒体",
          router: "/media",
        },
      ],
      swiperOptions: {
        //
        pagination: {
          //
          el: ".swiper-pagination",
          clickable: true,
        },
        // loop: true,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        // },
        // speed: 3000, //
      },
    };
  },
  created() {},
  methods: {
    handleScroll(e) {
      console.log(111, e);
    },
    checkRouter(nav, index) {
      this.$router.push({
        path: nav.router,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-wrapper {
  width: 100vw;

  img {
    width: 100%;
    height: 100%;
  }
}

.banner {
  width: 100vw;

  // height: 550px;
  img {
    width: 100vw;
    height: auto;
    display: block;
  }
}

.banner-nav {
  position: relative;

  .nav {
    box-sizing: border-box;
    width: 100%;
    padding: 0 200px;
    height: 80px;
    position: absolute;
    top: 0;
    z-index: 10;
    background: rgba(224, 224, 224, 0.2);
    box-shadow: 0px 4px 4px 1px rgba(224, 224, 224, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .nav-li {
      height: 100%;
      padding: 0 2vw;
      line-height: 80px;
      cursor: pointer;

      &:hover {
        .child-box {
          display: block;
        }
      }

      .nav-name {
        font-size: 1vw;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .active {
        color: #1d71d1;
        font-weight: 800;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        &::after {
          content: "";
          width: 20px;
          height: 2px;
          background: #1d71d1;
          position: absolute;
          bottom: 20px;
        }
      }

      .child-box {
        position: absolute;
        display: none;
        background: #ffffff;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px 8px 8px 8px;
        padding: 24px 0;
      }

      .nav-child {
        display: flex;
        // flex-wrap: wrap;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        .nav-child-li {
          cursor: pointer;
          font-size: 0.9vw;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4f4f4f;
          line-height: 21px;
          padding: 0 24px;
          border-right: 1px solid #bdbdbd;

          &:last-child {
            border: 0;
          }

          &:hover {
            color: #1d71d1;
          }
        }
      }
    }
  }

  .nav1 {
    position: static;
  }

  .nav2 {
    position: fixed;
    background-color: #1d71d1;
    top: 0;
    z-index: 99;

    .nav-li {
      height: 100%;
      padding: 0 2vw;
      line-height: 80px;
      cursor: pointer;

      &:hover {
        .child-box {
          display: block;
        }
      }

      .nav-name {
        font-size: 1vw;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
      }

      .active {
        color: #fff;
        font-weight: 800;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        &::after {
          content: "";
          width: 20px;
          height: 2px;
          background: #fff;
          position: absolute;
          bottom: 20px;
        }
      }

      .child-box {
        position: absolute;
        display: none;
        background: #ffffff;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px 8px 8px 8px;
        padding: 24px 0;
      }

      .nav-child {
        display: flex;
        // flex-wrap: wrap;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        .nav-child-li {
          cursor: pointer;
          font-size: 0.9vw;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4f4f4f;
          line-height: 21px;
          padding: 0 24px;
          border-right: 1px solid #bdbdbd;

          &:last-child {
            border: 0;
          }

          &:hover {
            color: #1d71d1;
          }
        }
      }
    }
  }
}

@media screen and(max-width:828px) {
  .banner {
    width: 100vw;
    height: 200px;

    img {
      width: 100vw;
      height: 200px;
      display: block;
    }
  }
}
</style>
