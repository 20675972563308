<template>
  <div class="home" v-if="mcieObj.miceName">
    <img class="bg" src="../assets/img/app-bg.png" alt="" />
    <div class="home1">
      <div class="timeOut">
        <div class="left">
          <div class="label">距离展会开幕还有：</div>
          <div class="time">
            <div class="day">{{ restStamp.day }}</div>
          </div>
          <div class="label unit">天</div>
        </div>
        <div class="right">
          <div class="Free-tickets" @click="goRouter('/sign-up')">
            <img src="../assets/img/titck-icon.png" alt="" />
            <span>采购商报名</span>
          </div>
          <div class="reservation" @click="goRouter('/reservation-sign-up')">
            <img src="../assets/img/exh-sign.png" alt="" />
            <span>展位预定</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home1">
      <div class="pavilion">
        <div class="h2-title fl-c-sb">
          <div class="fl-c">
            <img src="../assets/img/h2-title-icon.png" alt="" />
            <span>展馆一览</span>
          </div>
          <div class="right-all" @click="goPavilion">展馆列表 ></div>
        </div>
        <div class="container">
          <!-- <div class="one-column">
            <div class="item" @click="goDetail(pavilionList[0])">
              <img :src="pavilionList[0].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[0].name }}</div>
              </div>
            </div>
            <div class="item" @click="goDetail(pavilionList[1])">
              <img :src="pavilionList[1].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[1].name }}</div>
              </div>
            </div>
          </div>
          <div class="two-column">
            <div class="item" @click="goDetail(pavilionList[2])">
              <img :src="pavilionList[2].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[2].name }}</div>
              </div>
            </div>
            <div class="item" @click="goDetail(pavilionList[3])">
              <img :src="pavilionList[3].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[3].name }}</div>
              </div>
            </div>
            <div class="item" @click="goDetail(pavilionList[4])">
              <img :src="pavilionList[4].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[4].name }}</div>
              </div>
            </div>
          </div>
          <div class="three-column">
            <div class="item" @click="goDetail(pavilionList[5])">
              <img :src="pavilionList[5].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[5].name }}</div>
              </div>
            </div>
            <div class="item" @click="goDetail(pavilionList[6])">
              <img :src="pavilionList[6].url" alt="" />
              <div class="detail">
                <div class="name one-warp">{{ pavilionList[6].name }}</div>
              </div>
            </div>
          </div> -->
          <div
            class="item"
            v-for="(e, i) in pavilionList"
            :key="i"
            @click="goDetail(e)"
          >
            <img :src="e.url" alt="" />
            <div class="detail">
              <div class="name one-warp">{{ e.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mice-data" ref="data">
      <div class="h2-title fl-c-sb">
        <div class="fl-c">
          <img src="../assets/img/h2-title-icon.png" alt="" />
          <span>展会数据</span>
        </div>
      </div>
      <div class="container">
        <div class="item" v-for="(e, i) in dataList" :key="i">
          <img :src="e.icon" alt="" />
          <div class="num" v-if="isShow">
            <countTo
              :startVal="0"
              :endVal="e.num | strToNum"
              :duration="2000"
            ></countTo
            >{{ e.num | strToStr }}
          </div>
          <div class="bottom">{{ e.name }}</div>
        </div>
      </div>
    </div>
    <div class="home1" v-if="directoryList.length">
      <div class="exhibitors">
        <div class="h2-title fl-c-sb">
          <div class="fl-c">
            <img src="../assets/img/h2-title-icon.png" alt="" />
            <span>优质展商</span>
          </div>
          <div class="right-all" @click="goDirectorys">查看更多 ></div>
        </div>
        <div class="container">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="index in imgPage" :key="index">
                <div
                  class="directory"
                  v-for="(directory, i) in directoryList"
                  :key="directory.id"
                  @click="goDirectory(directory)"
                  v-if="i < index * 8 && i >= (index - 1) * 8"
                >
                  <img class="logo" :src="directory.logo" alt="" />
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="home1" v-if="videoList.length">
      <div class="news">
        <div class="h2-title fl-c-sb">
          <div class="fl-c">
            <img src="../assets/img/h2-title-icon.png" alt="" />
            <span>视频中心</span>
          </div>
          <div class="right-all" @click="goRouter('/video-center')">
            查看更多 >
          </div>
        </div>
        <div class="video">
          <div
            class="video-item"
            v-for="(item, index) in videoList"
            :key="index"
            v-if="index < 3"
          >
            <img
              src="@/assets/img/video/play.png"
              v-show="!item.isplay"
              alt=""
              @click="play(item, `homeVideo${index}`)"
            />
            <video
              :ref="`homeVideo${index}`"
              loop="loop"
              muted="muted"
              controls="controls"
              :src="item.src"
              @pause="handlePause"
              :data-index="index"
            ></video>
            <div class="desc two-warp">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home1">
      <div class="news">
        <div class="h2-title fl-c-sb">
          <div class="fl-c">
            <img src="../assets/img/h2-title-icon.png" alt="" />
            <span
              :class="{ active: categoryId == '324JaweZ1TMEW8MDshFiQ2' }"
              @click="changeCategoryId('324JaweZ1TMEW8MDshFiQ2')"
              >新闻资讯</span
            >
            <span
              :class="{ active: categoryId == '70Sfm5A8PjmTAEmzpHho6U' }"
              @click="changeCategoryId('70Sfm5A8PjmTAEmzpHho6U')"
              >展会咨询</span
            >
          </div>
          <div class="right-all" @click="goNews">查看更多 ></div>
        </div>
        <div class="container" v-if="newList.length">
          <div class="left">
            <div class="swiper-container1">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in newList"
                  :key="item.id"
                  v-if="index < 3"
                  @click="gotoDetail(item)"
                >
                  <div class="top">
                    <img :src="item.article.cover" alt="" />
                  </div>
                  <div class="desc fl-c-c">
                    <div class="alltime">
                      <div class="day">
                        {{ item.article.publishTime | changeDay }}
                      </div>
                      <div class="time">
                        {{ item.article.publishTime | changeTime }}
                      </div>
                    </div>
                    <div>
                      <div class="title two-warp">{{ item.article.title }}</div>
                      <div class="subMessage two-warp">
                        {{ item.article.subMessage }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination1"></div>
            </div>
          </div>
          <div class="right">
            <div
              class="new-li"
              v-for="(item, index) in newList"
              :key="index"
              @click="gotoDetail(item)"
              v-if="index > 2"
            >
              <div class="time">
                {{ item.article.publishTime | changeAllTime }}
              </div>
              <div class="title two-warp">{{ item.article.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home1" v-if="imgList.length">
      <div class="news">
        <div class="h2-title fl-c-sb">
          <div class="fl-c">
            <img src="../assets/img/h2-title-icon.png" alt="" />
            <span>媒体合作</span>
          </div>
        </div>
        <div class="friends">
          <div
            class="friend-item"
            v-for="(item, index) in imgList"
            :key="index"
            @click="goMedia(item)"
          >
            <img :src="item.image" />
          </div>
        </div>
        <!-- <div class="cooperation">
          <div id style="overflow: hidden">
            <div class="swiper-container2">
            
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import { directory, getArticleList, friendsList } from "@/api";
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import moment from "moment";
export default {
  components: { countTo },
  props: {
    mcieObj: {
      type: Object,
      defalut: {},
    },
    dataList: {
      type: Array,
      defalut: [],
    },
  },
  data() {
    return {
      directoryList: [],
      imgList: [],
      newList: [],
      imgPage: 1,
      isShow: false,
      timer: null,
      times: 0,
      restStamp: {
        day: "00",
        hour: "00",
        min: "00",
        seconds: "00",
      },
      categoryId: "324JaweZ1TMEW8MDshFiQ2",
    };
  },
  filters: {
    changeAllTime(val) {
      return moment.unix(val).format("YYYY/MM/DD");
    },
    changeTime(val) {
      return moment.unix(val).format("YYYY/MM");
    }, //DD
    changeDay(val) {
      return moment.unix(val).format("DD");
    },
    strToNum(val) {
      let numArr = val.match(/-?([1-9]\d*(\.\d*)*|0\.[1-9]\d*)/g);
      return numArr[0] * 1;
    },
    strToStr(val) {
      let numArr = val.match(/[^\u4e00-\u9fa50-9]/g);
      let str = "";
      numArr.forEach((e) => {
        str += e;
      });
      return str;
    },
  },
  created() {
    this.getdirectory();
    this.getArticleList();
    this.times = this.mcieObj.start_time - this.mcieObj.now;
    this.outTime();
    this.getfriendsList();
  },
  mounted() {
    window.addEventListener("scroll", this.windowScrollListener);
    // 遍历视频元素，为每个视频元素添加事件监听
    const videos = this.$refs;
    for (const name in videos) {
      if (
        videos.hasOwnProperty(name) &&
        videos[name] &&
        videos[name].nodeName === "VIDEO"
      ) {
        videos[name].addEventListener("pause", this.handlePause);
      }
    }
  },

  destroyed() {
    //离开页面时删除该监听
    window.removeEventListener("scroll", this.windowScrollListener);
    // 遍历视频元素，清除每个视频元素的事件监听
    const videos = this.$refs;
    for (const name in videos) {
      if (
        videos.hasOwnProperty(name) &&
        videos[name] &&
        videos[name].nodeName === "VIDEO"
      ) {
        videos[name].removeEventListener("pause", this.handlePause);
      }
    }
  },

  methods: {
    play(item, name) {
      item.isplay = true;
      this.$refs[name][0].play();
    },
    handlePause(e) {
      console.log(e.target); // 输出暂停的视频元素
      const index = e.target.getAttribute("data-index");
      this.videoList[index].isplay = false;
    },

    changeCategoryId(e) {
      this.categoryId = e;
      this.getArticleList();
    },
    goRouter(path) {
      this.$router.push({
        path,
      });
    },
    goMedia(e) {
      if (e.url !== "") {
        var el = document.createElement("a");
        document.body.appendChild(el);
        el.href = e.url;
        el.target = "_blank";
        el.click();
        document.body.removeChild(el);
      }
    },
    getArticleList() {
      this.list = [];
      getArticleList({
        page: 0,
        pageSize: 8,
        categoryId: this.categoryId,
      }).then((res) => {
        //'324JaweZ1TMEW8MDshFiQ2'
        if (res.status) {
          this.newList = res.data.list;
          this.$nextTick(() => {
            new Swiper(".swiper-container1", {
              loop: true, // 循环模式选项
              slidesPerView: 1,
              autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              },
              speed: 3000,
              spaceBetween: 20,
              pagination: {
                el: ".swiper-pagination1",
                clickable: true,
              },
            });
          });
        }
      });
    },
    windowScrollListener(e) {
      const winHeight =
        e.target.scrollTop || document.documentElement.scrollTop;
      if (
        this.$refs.data.getBoundingClientRect().top -
          this.$refs.data.offsetHeight <=
        0
      ) {
        this.isShow = true;
      }
    },
    goDetail(item) {
      this.$router.push({
        path: "/Pavilion/detail",
        query: {
          id: item.miceId,
        },
      });
    },
    goPavilion() {
      this.$router.push({
        path: "/Pavilion",
      });
    },
    goDirectory(item) {
      this.$router.push({
        path: "/exhibitor-list/detail",
        query: {
          id: item.id,
        },
      });
    },
    goDirectorys() {
      this.$router.push({
        path: "/Exhibitor-list",
      });
    },
    goNews() {
      if (this.categoryId == "324JaweZ1TMEW8MDshFiQ2") {
        this.$router.push({
          path: "/information",
        });
      } else {
        this.$router.push({
          path: "/news",
        });
      }
    },
    gotoDetail(e) {
      if (e.article.jump_url && e.article.jump_url !== "") {
        var el = document.createElement("a");
        document.body.appendChild(el);
        el.href = e.article.jump_url;
        el.target = "_blank";
        el.click();
        document.body.removeChild(el);
      } else {
        this.$router.push({
          path: "/information/detail",
          query: {
            id: e.article.id,
          },
        });
      }
    },
    getdirectory() {
      directory({ miceId: this.miceId, pageSize: 24, page: 0 }).then((res) => {
        if (res.status) {
          this.directoryList = res.data.list;
          this.imgPage =
            this.directoryList.length % 8 == 0
              ? this.directoryList.length / 8
              : Math.floor(this.directoryList.length / 8) + 1;
          this.$nextTick(() => {
            new Swiper(".swiper-container", {
              loop: true, // 循环模式选项
              slidesPerView: 1,
              grid: {
                fill: "column",
                rows: 2,
              },
              autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              },
              speed: 3000,
              spaceBetween: 20,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
            });
            // mySwiper.el.onmouseover = function () {
            //   mySwiper.autoplay.stop();
            // };

            // mySwiper.el.onmouseout = function () {
            //   mySwiper.autoplay.start();
            // };
          });
        }
      });
    },
    outTime() {
      this.timer = setInterval(() => {
        this.times--;
        this.formateTimeStamp(this.times);
        if (this.times <= 0) {
          this.show = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    formateTimeStamp(timeStamp) {
      if (timeStamp <= 0) {
        this.restStamp = {
          day: "00",
          hour: "00",
          min: "00",
          seconds: "00",
        };
        return;
      }
      var day;
      var hour;
      var min;
      var seconds;
      day = parseInt(timeStamp / (24 * 60 * 60)); // 计算整数天数
      var afterDay = timeStamp - day * 24 * 60 * 60; // 取得算出天数后剩余的秒数
      hour = parseInt(afterDay / (60 * 60)); // 计算整数小时数
      var afterHour = timeStamp - day * 24 * 60 * 60 - hour * 60 * 60; // 取得算出小时数后剩余的秒数
      min = parseInt(afterHour / 60); // 计算整数分
      seconds = parseInt(
        timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60
      ); // 取得算出分后剩余的秒数
      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.restStamp = {
        day: day,
        hour: hour,
        min: min,
        seconds: seconds,
      };
    },
    getfriendsList() {
      friendsList({ mice_id: this.miceId }).then((res) => {
        if (res.status) {
          this.imgList = res.data[0].list;
          // if (this.imgList.length > 5) {
          //   this.$nextTick(() => {
          //     let mySwiper = new Swiper(".swiper-container2", {
          //       slidesPerView: 4,
          //       spaceBetween: 30,
          //       // freeMode: true,
          //       speed: 2500,
          //       loop: true,
          //       loopAdditionalSlides: 0,
          //       autoplay: {
          //         delay: 0,
          //         disableOnInteraction: false,
          //       },
          //     });
          //     //鼠标覆盖停止自动切换
          //     mySwiper.el.onmouseover = function () {
          //       mySwiper.autoplay.stop();
          //     };

          //     //鼠标离开开始自动切换
          //     mySwiper.el.onmouseout = function () {
          //       mySwiper.autoplay.start();
          //     };
          //   });
          // }else{
          //     this.$nextTick(() => {
          //     let mySwiper = new Swiper(".swiper-container2", {
          //       slidesPerView: 3,
          //       spaceBetween: 30,
          //       // freeMode: true,
          //       loopAdditionalSlides: 0,
          //     });
          //     //鼠标覆盖停止自动切换
          //     mySwiper.el.onmouseover = function () {
          //       mySwiper.autoplay.stop();
          //     };

          //     //鼠标离开开始自动切换
          //     mySwiper.el.onmouseout = function () {
          //       mySwiper.autoplay.start();
          //     };
          //   });

          // }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home1 {
  width: 100%;
  background-color: #fff;
}

.bg {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  top: 0;
  z-index: -1;
}

.swiper-pagination {
  height: 20px;
}

.timeOut {
  margin: 0 auto;
  width: 1347px;
  height: 7vw;
  background: #1d71d1;
  border-radius: 8px;
  position: relative;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4vw;
  box-sizing: border-box;
  color: #fff;

  .left,
  .right,
  .Free-tickets,
  .reservation {
    display: flex;
    align-items: center;

    img {
      width: 6.6vw;
      height: 5.5vw;
    }
  }

  .left {
    .label {
      font-size: 1.25vw;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 28px;
    }

    .time {
      position: relative;
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7vw;
      height: 5.5vw;
      background-image: url("../assets/img/shadow-bg.png");
      background-size: 100%;

      .day {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        line-height: 80px;
        font-size: 60px;
        // font-family: DS-Digital-Bold Italic, DS-Digital;
        font-weight: 600;
        color: #1d71d1;
      }
    }

    .unit {
      margin-left: 16px;
    }

    img {
    }
  }

  .right {
    .Free-tickets {
      margin-right: 3vw;
      cursor: pointer;
    }

    .reservation {
      cursor: pointer;
    }

    span {
      margin-left: 12px;
      font-size: 1.25vw;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}

.pavilion {
  margin: 0 auto;
  padding-top: 30px;
  width: 1347px;
  background-color: #fff;

  .h2-title {
    margin-bottom: 55px;
  }

  .container {
    // column-count: 3; //想要排成的列数
    // column-gap: 25px;
    display: flex;
    .one-column {
      margin-right: 24px;
    }
    .two-column {
      margin-right: 24px;
    }
    .three-column {
    }
    .item {
      width: 100%;
      cursor: pointer;
      position: relative;
      margin-right: 12px;
      &:nth-child(5n) {
        margin-right: 0px;
      }

      img {
        width: 100%;
        object-fit: contain;
        margin-bottom: 24px;
      }

      .detail {
        position: absolute;
        bottom: 46px;
        z-index: 10;
        margin: 0 16px;
        width: calc(100% - 32px);
        background: rgba(255, 255, 255, 0.7);
        border-radius: 4px;
        padding: 9px 16px;
        box-sizing: border-box;

        .name {
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
        }

        .desc {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #828282;
          margin-top: 8px;
        }
      }
    }
  }
}

.mice-data {
  padding-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  background-size: 100% 700px;

  .h2-title {
    padding: 80px 287px;

    span {
      color: #fff !important;
    }
  }

  .container {
    padding: 0px 287px;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-around;

    .item {
      margin-bottom: 43px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      img {
        width: 108px;
        height: 108px;
        margin-bottom: 12px;
      }

      .num {
        font-size: 36px;
        letter-spacing: 2px;
        font-weight: 800;
        color: #fff;
        line-height: 42px;
        margin-left: 10px;
        margin-bottom: 12px;
      }

      .bottom {
        font-size: 20px;
        letter-spacing: 2px;
        font-weight: 500;
        color: #fff;
        line-height: 19px;
        margin-top: 12px;
        text-align: center;
      }
    }
  }
}

.exhibitors {
  margin: 0 auto;
  padding-top: 80px;
  width: 1347px;

  .h2-title {
    margin-bottom: 48px;
  }

  .container {
    .swiper-container {
      padding-bottom: 40px;
    }

    .swiper-slide {
      display: flex;
      flex-wrap: wrap;

      .directory {
        cursor: pointer;
        width: 24%;
        height: 135px;

        .logo {
          width: 100%;
          height: 130px;
          border: 1px dotted #e0e0e0;
        }
      }
    }
  }
}

.news {
  margin: 0 auto;
  padding-top: 80px;
  width: 1347px;

  .h2-title {
    padding-bottom: 48px;

    span {
      cursor: pointer;
      margin-right: 20px;
      color: #ccc;
    }

    .active {
      color: #1d71d1;
    }
  }

  .container {
    display: flex;
    padding-bottom: 40px;

    .left {
      width: 612px;

      .swiper-container1 {
        overflow: hidden;
        border-radius: 16px;
        border: 1px solid #e0e0e0;
      }

      .swiper-slide {
        width: 612px;
        box-sizing: border-box;
        cursor: pointer;

        .top {
          img {
            width: 100%;
            height: 355px;
            object-fit: cover;
          }
        }

        .desc {
          align-items: flex-start;
          padding: 24px;

          .alltime {
            margin-right: 24px;

            .day {
              font-size: 42px;
              font-weight: 600;
              color: #333333;
              line-height: 49px;
              margin-bottom: 8px;
            }

            .time {
              font-size: 16px;
              font-weight: 400;
              color: #4f4f4f;
              line-height: 19px;
            }
          }

          .title {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 16px;
          }

          .subMessage {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 19px;
          }
        }
      }

      .swiper-pagination1 {
        display: flex;
        justify-content: center;
        padding: 24px 0;
      }
    }

    .right {
      margin-left: 70px;
      flex: 1;

      .new-li {
        margin-bottom: 36px;
        cursor: pointer;

        .time {
          font-size: 16px;
          font-weight: 400;
          color: #4f4f4f;
          margin-bottom: 12px;
        }

        .title {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
          padding-bottom: 24px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
    }
  }
}

.cooperation {
  display: flex;
  position: relative;

  .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;

    .item {
      cursor: pointer;
      width: 300px !important;
      height: 200px;
      margin: 22px;

      img {
        width: 300px !important;
        height: 200px;
        object-fit: contain;
        border: 1px solid #dddd;
      }
    }
  }
}

.friends {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;

  .friend-item {
    cursor: pointer;
    width: 260px !important;
    height: 104px;
    border: 1px dashed #dddd;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    img {
      width: 144px !important;
      height: 62px;
      object-fit: contain;
    }

    &:hover {
      transform: scale(1.2);
      box-shadow: 6px 6px 6px #ddd;
    }
  }
}

.video {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;

  .video-item {
    cursor: pointer;
    width: 435px !important;
    height: 245px;
    position: relative;

    &:nth-child(3n-1) {
      margin: 0 20px;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 56px;
      height: 56px;
      z-index: 10;
    }

    video {
      width: 435px !important;
      height: 245px;
      object-fit: contain;
      border: 1px solid #dddd;
    }

    .desc {
      font-size: 24px;
      font-weight: 600;
    }

    video::-webkit-media-controls-play-button {
      display: none;
    }
  }
}

@media screen and(max-width:828px) {
  .timeOut {
    margin: 0 24px;
    width: calc(100% - 48px);
    height: 60px;
    background: #1d71d1;
    border-radius: 8px;
    position: relative;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: #fff;

    .right {
      display: none;
    }

    .left {
      flex: 1;
      display: flex;
      justify-content: center;

      .label {
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 28px;
      }

      .time {
        position: relative;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 40px;
        background-image: url("../assets/img/shadow-bg.png");
        background-size: 100%;

        .day {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          line-height: 80px;
          font-size: 26px;
          // font-family: DS-Digital-Bold Italic, DS-Digital;
          font-weight: 600;
          color: #1d71d1;
        }
      }

      .unit {
        margin-left: 16px;
      }

      img {
      }
    }

    .right {
      .Free-tickets {
        margin-right: 3vw;
        cursor: pointer;
      }

      .reservation {
        cursor: pointer;
      }

      span {
        margin-left: 12px;
        font-size: 1.25vw;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .pavilion {
    padding: 24px 12px;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;

    .h2-title {
      margin-bottom: 24px;
    }

    .container {
      // column-count: 1; //想要排成的列数
      // column-gap: 12px;
      display: block;
      .one-column {
        display: flex;
        flex-wrap: wrap;
        margin-right: 0;
      }
      .two-column {
        display: flex;
        flex-wrap: wrap;
        margin-right: 0;
      }
      .three-column {
      }
      .item {
        position: relative;
        cursor: pointer;
        width: 100%;
        margin-left: 0 !important;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          margin-bottom: 12px;
        }

        &:first-child {
          margin-top: 0px !important;
        }

        .detail {
          position: absolute;
          bottom: 30px;
          z-index: 10;
          margin: 0 12px;
          width: calc(100% - 24px);
          background: rgba(255, 255, 255, 0.5);
          border-radius: 4px;
          padding: 9px 16px;
          box-sizing: border-box;

          .name {
            font-size: 18px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }

          .desc {
            font-size: 12px;
            font-weight: 400;
            color: #828282;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .mice-data {
    width: 100%;
    padding-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: auto;

    .h2-title {
      padding: 24px;
    }

    .container {
      padding: 0 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        width: 100%;
        width: 33%;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
          margin-bottom: 12px;
        }

        .num {
          font-size: 14px;
          letter-spacing: 2px;
          font-weight: 800;
          color: #fff;
          line-height: 16px;
          margin-left: 10px;
          margin-bottom: 12px;
        }

        .bottom {
          font-size: 14px;
          letter-spacing: 2px;
          font-weight: 600;
          color: #fff;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }

  .exhibitors {
    padding: 0 12px;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;

    .h2-title {
      margin-bottom: 24px;
    }

    .container {
      .swiper-container {
        padding-bottom: 20px;
      }

      .swiper-slide {
        display: flex;
        flex-wrap: wrap;

        .directory {
          width: 24%;
          height: 50px;

          .logo {
            width: 100%;
            height: 50px;
            border: 1px dotted #e0e0e0;
          }
        }
      }
    }
  }

  .video {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 12px;

    .video-item {
      cursor: pointer;
      width: 100% !important;
      height: 235px;
      position: relative;
      margin-bottom: 20px;

      &:nth-child(3n-1) {
        margin: 0 20px;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 56px;
        height: 56px;
        z-index: 10;
      }

      video {
        width: 100% !important;
        height: 205px;
        object-fit: contain;
        border: 1px solid #dddd;
      }

      .desc {
        font-size: 18px;
        font-weight: 600;
      }

      video::-webkit-media-controls-play-button {
        display: none;
      }
    }
  }

  .news {
    padding: 0 12px;
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box;

    .h2-title {
      padding-bottom: 24px;
    }

    .container {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      .left {
        width: 100%;

        .swiper-container1 {
          overflow: hidden;
          border-radius: 16px;
          border: 1px solid #e0e0e0;
        }

        .swiper-slide {
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          .top {
            img {
              width: 100%;
              height: 200px;
              object-fit: cover;
            }
          }

          .desc {
            align-items: flex-start;
            padding: 12px;

            .alltime {
              margin-right: 12px;

              .day {
                font-size: 32px;
                font-weight: 600;
                color: #333333;
                line-height: 40px;
                margin-bottom: 8px;
              }

              .time {
                font-size: 14px;
                font-weight: 400;
                color: #4f4f4f;
                line-height: 19px;
              }
            }

            .title {
              font-size: 18px;
              font-weight: 600;
              color: #333333;
              margin-bottom: 16px;
            }

            .subMessage {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 19px;
            }
          }
        }

        .swiper-pagination1 {
          display: flex;
          justify-content: center;
          padding: 12px 0;
        }
      }

      .right {
        margin-left: 0px;
        flex: 1;

        .new-li {
          margin-bottom: 0px;
          cursor: pointer;

          .time {
            font-size: 16px;
            font-weight: 400;
            color: #4f4f4f;
            margin-bottom: 6px;
            padding-top: 12px;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #333333;
            line-height: 28px;
            padding-bottom: 12px;
            border-bottom: 1px solid #e0e0e0;
          }
        }
      }
    }
  }

  .bg {
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    z-index: -1;
  }

  .friends {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 40px;

    .friend-item {
      cursor: pointer;
      width: 47% !important;
      height: 120px;
      margin-bottom: 10px;

      img {
        width: 100% !important;
        height: 120px;
        object-fit: contain;
        border: 1px solid #dddd;
      }

      &:hover {
        transform: scale(1.2);
        box-shadow: 6px 6px 6px #ddd;
      }
    }
  }
}
</style>
