<template>
  <div id="app">
    <router-view />
    <nav v-if="device === 'mobile'">
      <div class="signUp" @click="goRouter('/audience-sign-up')">
        <img src="./assets/img/free-icon.png" alt="" />
        <span>参观报名</span>
      </div>
      <div class="signUp" @click="goRouter('/sign-up')">
        <img src="./assets/img/zy2-icon.png" alt="" />
        <span>采购商报名</span>
      </div>
      <div class="signUp" @click="goRouter('/reservation-sign-up')">
        <img src="./assets/img/reservation-icon.png" alt="" />
        <span>展位预定</span>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["device"]),
  },
  data() {
    return {};
  },
  methods: {
    goRouter(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>
<style lang="scss">
@media screen and(max-width:828px) {
  nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1000;
    height: 50px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    box-shadow: 10 -1px #efefef;
    display: flex;
    justify-content: space-around;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS<11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容iOS>= 11.2 */
    .signUp {
      flex: 1;
      font-size: 14px;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e0e0e0;
      img {
        width: 38px;
      }
      &:last-child {
        border-right: 0px;
      }
    }
  }
}
</style>
