let mixin = {
  data() {
    return {
      showNav: false, //显示导航
      // 图片父容器高度
      bannerHeight: 600,
      screenWidth: 0, // 浏览器宽度
      requestHeader: "",
      miceId: "6ZISTM5W5ocAD6SCuB7qee",
      // 6ZISTM5W5ocAD6SCuB7qee
      pavilionList: [
        {
          miceId: "4VyK1mdw7CVP8RMhphlIuM",
          url: require("../img/1/1.png"),
          list_url: require("../img/1/1-1.png"),
          p_title: require("../img/1/2-title.png"),
          Distribution: [require("../img/1/1-fenbu.png")],
          name: "民宿产业展",
          pavilion: "2号馆",
          desc: "展示户外集成装备、民宿家具、民宿家居、民宿景观、民宿设计建造、民宿建造材料、室内软装设计、民宿特色产品、民宿旅游即食商品、酒店用品等。",
          area: "20000m²",
          list: [
            "展示户外集成装备",
            "民宿家具",
            "民宿家居",
            "民宿景观",
            "民宿设计建造",
            "民宿建造材料",
            "室内软装设计",
            "民宿特色产品",
            "民宿旅游即食商品",
            "酒店用品",
          ],
        },
        // {
        //   miceId: "1vG2Q5qqVZiM3GrBjNsxW",
        //   url: require("../img/1/2.png"),
        //   list_url: require("../img/1/1-2.png"),
        //   name: "工业文旅展",
        //   pavilion: "3号馆",
        //   p_title: require("../img/1/3-title.png"),
        //   Distribution: [require("../img/1/2-fenbu.png")],
        //   desc: "展示工业精神、工业设计、工业遗产、工业旅游目的地、工业旅游装备、工业旅游文创等。 ",
        //   area: "10000m²",
        //   list: [],
        // },
        // {
        //   miceId: "4RsyT0CAFP6c4T51pgEVIs",
        //   url: require("../img/1/3.png"),
        //   list_url: require("../img/1/1-3.png"),
        //   Distribution: [require("../img/1/3-fenbu.png")],
        //   name: "旅游演艺展",
        //   pavilion: "4号馆",
        //   p_title: require("../img/1/4-title.png"),
        //   desc: "展示文娱旅游演艺、文娱旅游服务、文娱演出装备、旅游演艺设备、表演直播设备、表演服装舞美、数字文娱服务等。",
        //   area: "10000m²",
        //   list: [
        //     "文娱旅游演艺展区",
        //     "文娱演出装备展区",
        //     "旅游演艺设备展区",
        //     "表演直播设备展区",
        //     "数字文娱服务展区",
        //     "文娱规划设计展区",
        //   ],
        // },
        {
          miceId: "6VGvOGYslJauXSn48WBV76",
          url: require("../img/1/7.png"),
          list_url: require("../img/1/1-7.png"),
          name: "连锁加盟展 ",
          pavilion: "4号馆",
          p_title: require("../img/1/7-title.png"),
          Distribution: [
            require("../img/1/7-fenbu-7.png"),
          ],
          desc: "展示头部餐饮品牌、茶饮品牌、酒店品牌、零售品牌、居民服务品牌、休闲娱乐品牌、商务服务品牌等。从吃、娱、购三要素出发，全面赋能文旅产业，邀请全国各地投资者、创业者、文旅项目运营者及加盟商参观参会，为连锁品牌搭建合作交流平台，助力文旅景区和城市商圈实现品牌化发展与服务转型升级。",
          area: "10000m²",
          list: [
            // "陶瓷及现代工艺品展区",
            // "雕刻文化展区",
            // "刺绣类编制工艺类展区",
            // "红木艺术展区",
          ],
        },
        // {
        //   miceId: "4Lt3ktIy0B63mtCfy1UzOM",
        //   url: require("../img/1/4.png"),
        //   list_url: require("../img/1/1-4.png"),
        //   name: "旅游商品大赛 ",
        //   pavilion: "5号馆",
        //   p_title: require("../img/1/5-title.png"),
        //   Distribution: [require("../img/1/4-fenbu.png")],
        //   desc: "“中国特色旅游商品大赛”是全国最具影响力的旅游商品赛事活动，大赛紧紧围绕旅游商品创新开发、创意设计、品牌打造、宣传营销等环节，突出旅游商品的特色化，市场化，已成为中国旅游商品发展的风向标。每届大赛，全国各省市自治区（除港澳台地区）均组织参加，每届参赛作品不少于2000套、2万件。",
        //   area: "10000m²",
        //   list: [
        //     // "文娱旅游演艺展区",
        //     // "文娱演出装备展区",
        //     // "旅游演艺设备展区",
        //     // "表演直播设备展区",
        //     // "数字文娱服务展区",
        //     // "文娱规划设计展区",
        //   ],
        // },
        {
          miceId: "43FDh07fEDglap52lyfzts",
          url: require("../img/1/1-5.png"),
          list_url: require("../img/1/1-5.png"),
          name: "旅游商品展 ",
          pavilion: "2号馆",
          p_title: require("../img/1/6-title.png"),
          Distribution: [require("../img/1/5-fenbu.png")],
          desc: "展示各地特色旅游食品、茶品、饮品、酒类、纺织品、电子设备、电器、陶瓷、玻璃、金属品、合成品、竹木品、化妆品、个人装备品、鞋帽、首饰、玩具、工艺品、纪念品等特色旅游商品。",
          area: "10000㎡",
          list: [
            // "民宿资源展区",
            // "民宿设计建造展区",
            // "民宿用品展区",
            // "民宿商品展区",
          ],
        },
        {
          miceId: "300bm6VkLGk41f9UtZb7l4",
          url: require("../img/1/8.png"),
          list_url: require("../img/1/1-8.png"),
          name: "景区装备展 ",
          pavilion: "3号馆",
          p_title: require("../img/1/8-title.png"),
          Distribution: [require("../img/1/8-fenbu.png")],
          desc: "展示智慧景区设施、文旅科技装备、文旅景区装备、文旅交通装备、演艺体验项目、娱乐游乐设施、旅游厕所、文旅配套服务、商业美陈、户外露营装备等。着力打造文旅产业链上下游企业共交流、同发展、谋合作的一站式平台。",
          area: "10000m²",
          list: [
            "数字文旅成果展",
            "文旅+元宇宙展区",
            "沉浸式文旅体验展区",
            "人工智能展区",
            "智慧旅游展区",
            "文旅演艺展区",
            "创意文旅IP展区",
            "配套服务展区",
          ],
        },
      ],
      videoList: [
        {
          name: "文旅博览会",
          desc: "2024文旅博览会旨在加快促进我国文旅产业高质量发展，推动文旅项目提质升级，有效提升存量项目品质，创造市场增量，推进文旅融合、体旅融合等多元业态融合发展，释放消费潜力，拉动经济增长，搭建文旅专业交流合作平台。进一步改善旅游消费环境，释放旅游消费潜力，满足人民群众美好生活需要，促进文娱旅游消费持续强劲复苏，助力经济社会高质量发展。",
          src: require("@/assets/img/video/文旅博览会.mp4"),
          isplay: false,
        },
        {
          name: "民宿产业展",
          desc: "展示户外集成装备、民宿家具、民宿家居、民宿景观、民宿设计建造、民宿建造材料、室内软装设计、民宿特色产品、民宿旅游即食商品、酒店用品等。",
          src: require("@/assets/img/video/民宿产业展.mp4"),
          isplay: false,
        },
        {
          name: "连锁加盟展",
          desc: "展示头部餐饮品牌、茶饮品牌、酒店品牌、零售品牌、居民服务品牌、休闲娱乐品牌、商务服务品牌等。从吃、娱、购三要素出发，全面赋能文旅产业，邀请全国各地投资者、创业者、文旅项目运营者及加盟商参观参会，为连锁品牌搭建合作交流平台，助力文旅景区和城市商圈实现品牌化发展与服务转型升级。",
          src: require("@/assets/img/video/连锁加盟展.mp4"),
          isplay: false,
        },
        // {
        //   name: "旅游商品大赛",
        //   desc: "“中国特色旅游商品大赛”是全国最具影响力的旅游商品赛事活动，大赛紧紧围绕旅游商品创新开发、创意设计、品牌打造、宣传营销等环节，突出旅游商品的特色化，市场化，已成为中国旅游商品发展的风向标。每届大赛，全国各省市自治区（除港澳台地区）均组织参加，每届参赛作品不少于2000套、2万件。",
        //   src: require("@/assets/img/video/中国特色旅游商品大赛.mp4"),
        //   isplay: false,
        // },
        {
          name: "旅游商品展",
          desc: "展示各地特色旅游食品、茶品、饮品、酒类、纺织品、电子设备、电器、陶瓷、玻璃、金属品、合成品、竹木品、化妆品、个人装备品、鞋帽、首饰、玩具、工艺品、纪念品等特色旅游商品。",
          src: require("@/assets/img/video/特色旅游商品展.mp4"),
          isplay: false,
        },
        {
          name: "景区装备展",
          desc: "展示智慧景区设施、文旅科技装备、文旅景区装备、文旅交通装备、演艺体验项目、娱乐游乐设施、旅游厕所、文旅配套服务、商业美陈、户外露营装备等。着力打造文旅产业链上下游企业共交流、同发展、谋合作的一站式平台。",
          src: require("@/assets/img/video/景区装备展.mp4"),
          isplay: false,
        },
      ],
    };
  },
  created() {},
  mounted() {
    // 首次加载时,需要调用一次
    this.setSize();
    // 窗口大小发生改变时,调用一次
    // window.onresize = () => {
    //   this.setSize();
    // };
    window.addEventListener("resize", () => this.setSize());
  },
  methods: {
    setSize: function () {
      this.screenWidth = window.innerWidth;
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = (600 / 1550) * this.screenWidth;
      if (this.screenWidth > 750) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    },
    //时间转换
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    goBack() {
      this.$router.back();
    },
    // 手机号验证
    verifyPhone(value) {
      let regular = /^1[3|4|5|7|8]\d{9}$/;
      if (regular.test(value)) {
        return true;
      } else {
        return false;
      }
    },
    //封装精确的获取数据类型
    getType(source) {
      return Object.prototype.toString.call(source).slice(8, -1);
    },
    //封装一个深拷贝的函数
    deepCopy(source) {
      //source 给一个资源数据，返回复制好的对象
      //1.判断如果是数组，创建一个空数组，如果是对象，创建一个对象，其他直接返回
      if (this.getType(source) === "Array") {
        var res = [];
      } else if (this.getType(source) === "Object") {
        var res = {};
      } else {
        return source;
      }
      //2.循环赋值
      for (var key in source) {
        if (this.getType(source[key]) === "Array" || "Object") {
          //如果要复制的值还是一个数组，或者是一个对象，直接等于就还是一个浅拷贝，
          res[key] = this.deepCopy(source[key]); //如果数组赋值还是一个对象
        } else {
          res[key] = source[key];
        }
      }
      return res;
    },

    // ES6 数组内对象去重
    reduceArrayObj(arr, key) {
      const hash = {};
      const newArr = arr.reduce((item, next) => {
        hash[next[key]] ? "" : (hash[next[key]] = true && item.push(next));
        return item;
      }, []);

      return newArr;
    },
  },
};
export default mixin;
